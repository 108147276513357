import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

const Container = styled.div`
  margin: 40px;
  text-align: center;
`

export default function NotFoundPage() {
  return (
    <Layout>
      <Container>
        <h3>404: Not Found</h3>
      </Container>
    </Layout>
  )
}
